<template>
  <div class="content">
    <el-row class="bgW">
      <el-col :span="22">
        <!--检索条件-->
        <el-form class="search" :inline="true" size="mini" :model="search">
          <el-form-item label="入会日期">
            <el-date-picker
              v-model="search.join_time_start"
              type="date"
              class="w130"
              placeholder="选择日期"
              value-format="yyyy-MM-dd"
            ></el-date-picker>
         
		    <!-- <el-date-picker
		      v-model="search.join_time_end"
		      type="date"
		      class="w130"
		      placeholder="选择日期"
		      value-format="yyyy-MM-dd"
		    ></el-date-picker> -->
		  </el-form-item>
          <el-form-item label="姓名">
            <el-input
              v-model="search.customer_name"
              maxlength="30"
              class="w100"
              placeholder="请输入"
              clearable
            />
          </el-form-item>
          <el-form-item label="手机号">
            <el-input
              v-model="search.telephone"
              maxlength="30"
              class="w120"
              placeholder="请输入"
              clearable
            />
          </el-form-item>
          <el-form-item label="性别">
            <el-select v-model="search.sex" clearable class="w80">
              <el-option
                v-for="item in options.sex"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              />
            </el-select>
          </el-form-item>
          <el-form-item label="年龄">
            <el-input
              v-model="search.agemin"
              maxlength="30"
              class="w84"
              placeholder="最小值"
              clearable
            />
            <span></span>
            <el-input
              v-model="search.agemax"
              maxlength="30"
              class="w84"
              placeholder="最大值"
              clearable
            />
          </el-form-item>
          <el-form-item label="生日">
            <el-date-picker
              v-model="search.birthday"
              type="date"
              class="w130"
              placeholder="选择日期"
              value-format="yyyy-MM-dd"
            ></el-date-picker>
          </el-form-item>
          <el-form-item label="护理师">
            <el-input
              v-model="search.source_name"
              maxlength="30"
              class="w100"
              placeholder="请输入"
              clearable
            />
          </el-form-item>

          <el-form-item label="门店/门诊" v-if="!userInfo.shop_id">
            <el-select
              v-model="search.shop_id"
              clearable
              remote
              filterable
              :remote-method="remoteMethod"
              placeholder="请输入"
              class="w120"
            >
              <el-option
                v-for="item in options.shop_id"
                :key="item.shopId"
                :label="item.shopName"
                :value="item.shopId"
              />
            </el-select>
          </el-form-item>
          <el-form-item label="会员等级">
            <el-select v-model="search.membership_leve" clearable class="w120">
              <el-option
                v-for="item in options.membership_leve"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              />
            </el-select>
          </el-form-item>
          <el-form-item label="会员状态">
            <el-select v-model="search.huiyuan_status" clearable class="w120">
              <el-option
                v-for="item in options.huiyuan_status"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              />
            </el-select>
          </el-form-item>
          <el-form-item label="会员到期日">
            <el-date-picker
              v-model="search.be_overdue_time"
              type="date"
              class="w130"
              placeholder="选择日期"
              value-format="yyyy-MM-dd"
            ></el-date-picker>
          </el-form-item>
        </el-form>
      </el-col>
      <el-col :span="2" style="text-align: right;">
        <el-button type="primary" icon="el-icon-search" size="mini" @click="getList('search')">搜索</el-button>
      </el-col>
    </el-row>
    <div class="accountCon">
      <div class="btnBox">
        <el-button
          v-if="!userInfo.shop_id"
          type="primary"
          :disabled="!idList.length"
          class="mb10"
          size="mini"
          @click="hint('导出选中列表')"
        >导出数据</el-button>
      </div>
      <!--列表-->
      <el-row>
        <el-col :span="24">
          <el-table
            :data="list"
            size="small"
            border
            :cell-style="$style.cellStyle"
            :header-cell-style="$style.rowClass"
            @selection-change="handleSelectionChange"
          >
            <ElTableColumn v-if="!userInfo.shop_id" type="selection" width="50" />
            <ElTableColumn label="入会时间" width="150" prop="join_time" />
            <ElTableColumn label="姓名"  prop="customer_name" />
            <ElTableColumn label="手机号" prop="telephone" />
            <ElTableColumn label="性别" width="50" prop="sex" />
            <ElTableColumn label="年龄" width="50" prop="age" />
            <ElTableColumn label="生日" prop="birthday" width="100" />
            <ElTableColumn label="护理师" prop="nurse_name" />
            <ElTableColumn label="门店/门诊" prop="shop_name" />
            <ElTableColumn label="会员等级" width="70" prop="grade_name" />
            <ElTableColumn label="会员状态" prop="huiyuan_status" />
            <ElTableColumn label="会员到期日" prop="be_overdue_time" />
            <ElTableColumn label="累计消费" prop="consumption" />
            <ElTableColumn label="客户分类" prop="class_name" />
            <ElTableColumn label="操作" fixed="right">
              <template slot-scope="{ row }">
                <span class="theme" @click="tapBtn('客户详情',row.id)">查 看</span>
              </template>
            </ElTableColumn>
          </el-table>
          <pagination
            class="page tc mt10"
            :total="page.total"
            :page.sync="page.page"
            :limit.sync="page.limit"
            @pagination="getList"
          />
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import pagination from "@/components/Pagination";
import { getShopList } from "@/api/shop";
import {
  clientList,
  ssettingList,
  statusSetting,
  getGradeList,
  clientExport
} from "@/api/client";

class Search {
  join_time_start = "";
  //join_time_end = "";
  customer_name = "";
  telephone = "";
  sex = "";
  agemin = "";
  agemax = "";
  source_id = "";
  birthday = "";
  effective_entry = "";
  membership_leve = "";
  huiyuan_status = "";
  shop_id = "";
  member_classification = "";
  source_name = "";
  be_overdue_time = "";
}
class Options {
  sex = [
    { id: 0, name: "全部" },
    { id: 1, name: "男" },
    { id: 2, name: "女" }
  ];
  source_id = [];
  effective_entry = [
    { id: 0, name: "全部" },
    { id: 1, name: "是" },
    { id: 2, name: "否" }
  ];
  membership_leve = [];
  shop_id = [];
  member_classification = [];
  repair_progress = [];
  huiyuan_status = [
    { id: 1, name: "已过期" },
    { id: 2, name: "生效中" },
    { id: 3, name: "即将过期" }
  ];
}
class Page {
  total = 0;
  page = 1;
  limit = 10;
}
export default {
  name: "ClientList",
  components: {
    pagination
  },
  data() {
    return {
      search: new Search(),
      options: new Options(),
      page: new Page(),
      list: [],
      idList: ""
    };
  },
  computed: {
    ...mapGetters(["userInfo"])
  },
  filters: {
    statusFilter(val) {
      return ["启用", "禁用"][+val];
    }
  },
  mounted() {
    this.getOptions();
    this.getList();
  },
  methods: {
    // 获取选项
    getOptions() {
      ssettingList({ page: 1, limit: 100000 }).then(res => {
        let that = this;
        res.list.forEach(function(item) {
          if (item.category_id == 4) {
            that.options.source_id.push(item);
          } else if (item.category_id == 5) {
            that.options.repair_progress.push(item);
          } else if (item.category_id == 8) {
            that.options.member_classification.push(item);
          }
        });
      });
      getGradeList().then(res => {
        this.options.membership_leve = res.list;
      });
    },
    // 搜索门店
    remoteMethod(e) {
      getShopList({ page: 1, limit: 100000, shopName: e }).then(res => {
        this.options.shop_id = res.list;
      });
    },
    // 获取列表
    getList(option) {
      if (option === "search") this.page = new Page();
      clientList({ ...this.search, ...this.page, is_join_shop: 2 }).then(
        res => {
          this.list = res.list;
          this.page.total = res.dataCount;
        }
      );
    },
    // 跳转按钮
    tapBtn(routerName, id) {
      const query = { id: id, activeName: "first" };
      //this.$router.push({ name: routerName, query });
	  let route = this.$router.resolve({ name: routerName, query });
	  window.open(route.href, '_blank');
    },
    // 提示
    hint(text) {
      this.$confirm(`${text},是否确定？`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      })
        .then(() => {
          this.getExport();
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消"
          });
        });
    },
    // 导出
    getExport() {
      clientExport({ id: this.idList, type: 3 }).then(res => {
        window.open(res.data.url);
      });
    },
    // 改变表格选中状态
    handleSelectionChange(val) {
      this.idList = val.map(i => {
        return i.id;
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.mainColor {
  color: #2dc0a3;
}
.content {
  height: calc(100% - 56px);
  overflow-y: auto;
}
.bgW {
  background: #fff;
  border-radius: 3px;
  padding: 10px;
  margin-bottom: 20px;
}

.el-form-item {
  margin: 5px 20px 3px 0;
}
.accountCon {
  background: #fff;
  padding: 0px 10px 80px;
  .btnBox {
    display: flex;
    justify-content: space-between;
  }
}
</style>
